import {ThreeUnitDetail} from '../ThreeUnitDetail/ThreeUnitDetail';
import {Box2, Euler, Mesh, MeshStandardMaterial, Shape, Vector2, Vector3} from 'three';
import {ThreeUnit} from '../../ThreeUnit/ThreeUnit';
import {TPoint2D} from '../../../../../../common-code/types/TPoint2D';
import {TSideType} from '../../../../../../common-code/types/TSideType';
import {
    PRICE_CELL_TABLETOP,
    SIDE_TYPE_BACK,
    SIDE_TYPE_LEFT,
    SIDE_TYPE_RIGHT
} from '../../../../../../common-code/constants';
import {ICoverMainPoints} from '../../../../interfaces/ICoverMainPoints';
import {TOptionalSizes} from '../../../../../../common-code/types/TOptionalSizes';
import {TPositionSideType} from '../../../../../../common-code/types/TPositionSideType';
import {IMaterialTextures} from '../../../../interfaces/IMaterialTextures';
import {TKDetailPriceType} from '../../../../../../common-code/types/TKDetailPriceType';
import {TDirectionSideType} from '../../../../../../common-code/types/TDirectionSideType';
import {i18n} from '../../../../../i18n';
import {IImportOffer} from '../../../../../../common-code/interfaces/api/IImportOffer';
import {ISaveKUnitDetailData} from '../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';
import {IDetailData} from '../../../../../../common-code/interfaces/materials/IDetailData';
import {IDetailKitPriceData} from '../../../../../../common-code/interfaces/catalog/IDetailKitPriceData';
import {CommonHelper} from 'common-code';
import {IDetailPriceData} from '../../../../../../common-code/interfaces/catalog/IDetailPriceData';
import {IKUnitDetailNeighbors} from '../../../../interfaces/IKUnitDetailNeighbors';
import {Sphere} from 'three/src/math/Sphere';
import {KitchenHelper} from 'common-code';
import {IOffer} from '../../../../../../common-code/interfaces/catalog/IOffer';
import {IGlobalSidePoints} from '../../../../interfaces/IGlobalSidePoints';
import {TLine3D} from '../../../../../../common-code/types/TLine3D';
import {MathHelper} from 'common-code';
import {ThreeMathHelper} from '../../../../helpers/ThreeMathHelper/ThreeMathHelper';
import {MAX_CACHE_POOL_SIZE} from '../../../../constants';
import {ICreateObjectData} from '../../../../../../common-code/interfaces/createData/ICreateObjectData';
import {TSizes} from '../../../../../../common-code/types/geometry/TSizes';

/**
 * Класс деталей модулей кухни
 */
export class ThreeKUnitDetail extends ThreeUnitDetail {
    saveData: ISaveKUnitDetailData;
    unit: ThreeUnit;
    shape: Shape;
    leftPoints?: Vector2[];
    rightPoints?: Vector2[];
    correctLeftPoints?: Vector2[];
    correctRightPoints?: Vector2[];
    isUnion: boolean;
    body: Mesh;
    materialData: IDetailData;
    materialTextures: IMaterialTextures;
    priceData?: IDetailKitPriceData;
    neighbors: IKUnitDetailNeighbors;
    shapePoints?: Vector2[];
    threeVector3: Vector3;

    constructor(options: ISaveKUnitDetailData, unit: ThreeUnit) {
        super(options, unit);
        this.unit = unit;
        this.saveData = this.initThreeUnitSaveData(options);
        this.shape = new Shape();
        this.body = new Mesh();
        this.isUnion = false;
        this.ready = false;
        this.materialData = this.initMaterialData();
        this.materialTextures = this.loadTextures();
        this.neighbors = this.initNeighbors();
        this.initPriceType();
        this.threeVector3 = new Vector3();
    }

    public initState(isRebuild?: boolean) {
        this.correctMaterialOffset();
        this.calculateSidePoints();
        this.calculateCorrectSidePoints();
        this.createShape();
        this.createBody();
        super.initState(isRebuild);
    }

    public createView(isRebuild?: boolean) {
        this.addToScene();
        super.createView(isRebuild);
        this.ready = true;
    }

    public getData(): ISaveKUnitDetailData {
        let data: ISaveKUnitDetailData = CommonHelper.deepCopy(this.saveData);
        data.material = this.materialData.id;
        return data;
    }

    public getShapePoints(): Vector2[] {
        return this.shape.getPoints();
    }

    public getPriceData(): IDetailKitPriceData | undefined {
        return this.priceData;
    }

    public getPriceType(): TKDetailPriceType | undefined {
        return this.saveData.priceType;
    }

    public getPrice() {
        let priceData: IDetailKitPriceData | undefined;

        priceData = this.getPriceData();
        if (priceData) {
            return priceData.price;
        }
        return 0;
    }

    public getPriceCollectionId(): string {
        return this.getMaterialData().id + '_' + this.getHeight() + '_' + this.getWidth()
    }

    public getMaterialData(): IDetailData {
        return this.materialData;
    }

    public getDetailPrice(detailPriceData?: IDetailPriceData): number {
        return detailPriceData ? detailPriceData.price : 0;
    }

    public getDetailStock(detailPriceData?: IDetailPriceData): number {
        return detailPriceData && detailPriceData.stock ? detailPriceData.stock : 0;
    }

    public getDetailFormatStock(detailPriceData?: IDetailPriceData): string {
        let stock: number = this.getDetailStock(detailPriceData);
        return stock ? ''+stock + ' ' + i18n.t('шт') : '-';
    }

    public setPriceData(priceData: IDetailKitPriceData) {
        this.priceData = priceData;
        this.service.setOrderPartsToDetailPrice(priceData, this.service.getOrderParts());
    }

    public getGroupId(): number | undefined {
        if (this.saveData.groupId) {
            return this.saveData.groupId;
        }

        return undefined;
    }

    public getGroupItems(): ThreeKUnitDetail[] {
        let details: ThreeKUnitDetail[] = [];
        let detail: ThreeKUnitDetail;

        details.push(this);

        if (this.getGroupId()) {
            for (detail of this.getOtherDetails()) {
                if (detail.getGroupId() === this.getGroupId()) {
                    details.push(detail);
                }
            }
        }

        return details;
    }

    public getOfferIds(): string[] {
        let offers: IOffer[];
        let offer: IOffer;
        let offerIds: string[] = [];

        offers = this.getOffers();
        for (offer of offers) {
            offerIds.push(offer.importOffer[this.service.getOfferExternalId()]);

        }

        return offerIds;
    }

    public getOffers(): IOffer[] {
        let offers: IOffer[] = [];
        let itemPriceData: IDetailPriceData;

        if (this.priceData && this.priceData.kit) {
            for (itemPriceData of this.priceData.kit) {
                if (itemPriceData.offer && itemPriceData.count > 0) {
                    offers.push({
                        importOffer: itemPriceData.offer,
                        price: itemPriceData.price,
                        oldPrice: itemPriceData.oldPrice,
                        active: itemPriceData.active,
                        stock: itemPriceData.stock,
                        count: itemPriceData.count,
                        part: itemPriceData.part,
                    });
                }
            }
        }

        return offers;
    }

    public getAvailableMaterialIds(createObjectData: ICreateObjectData | undefined): string[] | undefined {
        if (this.isIncludeModulePrice() && createObjectData && createObjectData.availableTabletopMaterials) {
            if (createObjectData.availableTabletopMaterials['' + this.getWidth()]) {
                return createObjectData.availableTabletopMaterials['' + this.getWidth()];
            }
            if (createObjectData.availableTabletopMaterials['default']) {
                return createObjectData.availableTabletopMaterials['default'];
            }
        }
        return undefined;
    }

    public isCanUnion(): boolean {
        return this.saveData.canUnion === true;
    }

    public getName() {
        return this.materialData.title + ' ' + this.getGoodLength() + ' ' + i18n.t('мм');
    }

    public isIncludeModulePrice(): boolean {
        return this.saveData.includeModulePrice !== undefined ? this.saveData.includeModulePrice : false;
    }

    public getMaterialId(): string {
        return this.materialData.id;
    }

    public getMaterialTitle(): string {
        return this.materialData.title;
    }

    public getFunctionalType(): string | undefined {
        return this.saveData.functionalType;
    }

    public getSideType(): TDirectionSideType {
        return this.unit.getSideType();
    }

    public getUnionYPosition(): number {
        return 0;
    }

    public setCenterPosition() {
        if (!this.view3d.userData.centerPosition ||
            !(this.view3d.userData.centerPosition instanceof Vector3)) {
            this.view3d.userData.centerPosition = new Vector3()
        }
        this.view3d.userData.centerPosition = this.view3d.userData.centerPosition.copy(this.view3d.position).applyMatrix4(this.unit.view3d.matrixWorld);
    }

    public getVendorCode(offer?: IImportOffer): string {
        if (offer && offer.vendorCode) {
            return offer.vendorCode;
        }

        return '-';
    }

    public getSpecName(offer?: IImportOffer): string {
        if (offer && offer.name) {
            return offer.name;
        }
        return this.getName();
    }

    public getSpecCount(): number {
        if (this.priceData && this.priceData.count) {
            return this.priceData.count;
        }

        return 0;
    }

    public getStock(): number {
        if (this.priceData && this.priceData.stock) {
            return this.priceData.stock;
        }

        return 0;
    }

    public getSpecFormatCount(): string {
        let count = this.getSpecCount();

        return count ? count + ' ' + i18n.t('шт') : this.getGoodLength() + ' ' + i18n.t('мм');
    }

    public getDetailSpecCount(detailPriceData?: IDetailPriceData): number {
        if (detailPriceData && detailPriceData.count) {
            return detailPriceData.count;
        }

        return 0;
    }

    public getDetailSpecFormatCount(detailPriceData?: IDetailPriceData): string {
        let count: number = this.getDetailSpecCount(detailPriceData);

        return count ? count + ' ' + i18n.t('шт') : '-';
    }



    public calculateGlobalFrontVector() {
        super.calculateGlobalFrontVector();
        this.setCenterPosition();
    }

    public getGlobalSidePoints(cover: Mesh = this.cover): IGlobalSidePoints {
        return this.getSquareDetailGlobalSidePoints();
    }

    public getSquareDetailGlobalSidePoints(cover: Mesh = this.cover): IGlobalSidePoints {
        if (!this.correctLeftPoints || !this.correctRightPoints) {
            throw new Error('error-ThreeTabletop-getGlobalSidePoints');
        }
        let coverId: string;
        let globalSidePoints: IGlobalSidePoints;

        coverId = CommonHelper.md5({
            id: cover.uuid,
            position: cover.name === 'selectCover'? this.getGlobalPosition().toArray() : cover.position.toArray(),
            rotation: cover.name === 'selectCover'? this.getGlobalRotation().toArray() : cover.rotation.toArray(),
            parentPosition: this.unit.getGlobalPosition().toArray(),
            parentRotation: this.unit.getGlobalRotation().toArray()
        });
        if (this.globalSidePoints[coverId]) {
            return this.globalSidePoints[coverId];
        }
        this.view3d.updateMatrixWorld();
        globalSidePoints = {
            bottom: [],
            top: [],
            back: this.getGlobalSideItemPoints([this.correctLeftPoints[0], this.correctRightPoints[0]]),
            front: this.getGlobalSideItemPoints([this.correctLeftPoints[this.correctLeftPoints.length - 1], this.correctRightPoints[this.correctRightPoints.length - 1]]),
            left: this.getGlobalSideItemPoints(this.correctLeftPoints),
            right: this.getGlobalSideItemPoints(this.correctRightPoints)
        }
        if (Object.keys(this.globalSidePoints).length > MAX_CACHE_POOL_SIZE) {
            delete this.globalSidePoints[Object.keys(this.globalSidePoints)[0]];
        }
        this.globalSidePoints[coverId] = globalSidePoints;

        return globalSidePoints;
    }

    public getGlobalSideItemPoints(points: Vector2[]): TLine3D[] {
        let sideItemPoints: TLine3D[] = [];
        let index: number;

        for (index = 0; index < points.length - 1; index++) {
            if (!MathHelper.isEqualPoints2D(points[index], points[index + 1])) {
                sideItemPoints.push({
                    pointA: ThreeMathHelper.toPoint3D(this.threeVector3.set(
                        points[index].x,
                        0,
                        points[index].y
                    ).applyMatrix4(this.view3d.matrixWorld)),
                    pointB: ThreeMathHelper.toPoint3D(this.threeVector3.set(
                        points[index + 1].x,
                        0,
                        points[index + 1].y
                    ).applyMatrix4(this.view3d.matrixWorld))
                });
            }
        }

        return sideItemPoints;
    }

    public clearNeighbors() {
        this.neighbors = this.initNeighbors();
    }

    public getPositionType(): TPositionSideType {
        return this.saveData.positionType || SIDE_TYPE_BACK;
    }

    public getVisibleTitle(): string {
        return i18n.t('Деталь');
    }

    public isVisible(): boolean {
        return !!this.saveData.isVisible;
    }

    public isSelfVisible(): boolean {
        return !!this.saveData.isSelfVisible;
    }

    public isCalculate(): boolean {
        return !!this.saveData.isCalculate && this.materialData.notPrice !== true;
    }

    public isView3dVisible(): boolean {
        return this.view3d.visible;
    }

    public setVisible(isVisible: boolean) {
        if (this.saveData.isSelfVisible !== undefined) {
            this.saveData.isVisible = this.saveData.isSelfVisible;
        } else {
            this.saveData.isVisible = isVisible;
        }
        this.updateView3dVisible();
    }

    public setSelfVisible(isVisible: boolean) {
        this.saveData.isSelfVisible = isVisible;
        this.saveData.isVisible = isVisible;
        this.updateView3dVisible();
    }

    public clearSelfVisible() {
        this.saveData.isSelfVisible = undefined;
    }

    public setIsUnion(value: boolean) {
        this.isUnion = value;
        this.updateView3dVisible();
    }

    public hide() {
        this.setVisible(false);
    }

    public show() {
        this.setVisible(true);
    }

    public getGlobalPosition(): Vector3 {
        this.unit.view3d.updateMatrixWorld();
        return this.globalPosition.copy(this.view3d.position).applyMatrix4(this.unit.view3d.matrixWorld);
    }

    public getGlobalRotation(): Euler {
        return this.unit.view3d.rotation;
    }

    public getSizes(): TSizes {
        return {
            length: this.getLength(),
            width: this.getWidth(),
            height: this.getHeight(),
        }
    }

    public getOrderPart(index: number): number | undefined {
        if (!this.service.getCanOrderPart()) {
            return undefined;
        }
        if (this.unit.orderParts && this.unit.orderParts[PRICE_CELL_TABLETOP] &&
            this.unit.orderParts[PRICE_CELL_TABLETOP][index] !== undefined) {
            return this.unit.orderParts[PRICE_CELL_TABLETOP][index].part;
        }

        return undefined;
    }

    public isAvailableFunctionalType(functionalType?: string): boolean {
        return true;
    }

    public getHeight(): number {
        if (!this.saveData.sizes || !this.saveData.sizes.height) {
            throw new Error('error-ThreeKUnitDetail-getHeight');
        }

        return this.saveData.sizes.height;
    }

    public getLength(): number {
        if (!this.saveData.sizes || !this.saveData.sizes.length) {
            throw new Error('error-ThreeKUnitDetail-getLength');
        }

        return this.saveData.sizes.length;
    }

    public getWidth(): number {
        if (!this.saveData.sizes || !this.saveData.sizes.width) {
            throw new Error('error-ThreeKUnitDetail-getWidth');
        }

        return this.saveData.sizes.width;
    }

    public getGoodLength(): number {
        if (this.saveData.calculateSizes && this.saveData.calculateSizes.length) {
            return KitchenHelper.calculateSizeByParent(
                this.saveData.calculateSizes.length,
                this.unit.getWidth(),
                this.service.getDataForSizeByParent()
            );
        }

        return this.getLength();
    }

    public getGoodHeight(): number {
        if (this.saveData.calculateSizes && this.saveData.calculateSizes.height) {
            return KitchenHelper.calculateSizeByParent(
                this.saveData.calculateSizes.height,
                this.unit.getHeight(),
                this.service.getDataForSizeByParent()
            );
        }

        return this.getHeight();
    }

    public getGoodWidth(): number {
        if (this.saveData.calculateSizes && this.saveData.calculateSizes.width) {
            return KitchenHelper.calculateSizeByParent(
                this.saveData.calculateSizes.width,
                this.unit.getDepth(),
                this.service.getDataForSizeByParent()
            );
        }

        return this.getWidth();
    }

    public getGlobalMainPoints(cover: Mesh = this.cover): ICoverMainPoints {
        throw new Error('replace-ThreeKUnitDetail-getGlobalMainPoints');
    }

    public createBody() {
        throw new Error('replace-ThreeKUnitDetail-createBody');
    }

    public rebuildBody() {
        this.removeBody();
        this.createBody();
    }

    public removeBody() {
        this.view3d.remove(this.body);
    }

    public getLeftPoints(): Vector2[] {
        if (!this.leftPoints) {
            throw new Error('error-ThreeKUnitDetail-getLeftPoints');
        }
        return this.leftPoints;
    }

    public getRightPoints(): Vector2[] {
        if (!this.rightPoints) {
            throw new Error('error-ThreeKUnitDetail-getRightPoints');
        }
        return this.rightPoints;
    }

    public getGlobalSphere(): Sphere {
        if (!this.selectCover.geometry.boundingSphere) {
            this.selectCover.geometry.computeBoundingSphere();
        }
        if (!this.selectCover.geometry.boundingSphere) {
            throw new Error('error-CommonObject-getGlobalSphere');
        }
        this.globalSphere.set(this.getGlobalPosition(), this.selectCover.geometry.boundingSphere.radius)

        return this.globalSphere;
    }

    public getTechnologMapCuttingGap(): number {
        return 0;
    }

    public getTechnologMapFacadeId(): string {
        return this.unit.getTechnologMapFacadeId();
    }

    protected initNeighbors(): IKUnitDetailNeighbors {
        return {
            bottom: undefined,
            top: undefined,
            back: undefined,
            front: undefined,
            left: undefined,
            right: undefined
        };
    }

    protected getOtherDetails(): ThreeKUnitDetail[] {
        return [];
    }

    protected initPriceType() {

    }

    protected initMaterialData(): IDetailData {
        throw new Error('replace-ThreeKUnitDetail-initMaterialData');
    }

    protected loadTextures(): IMaterialTextures {
        return this.service.loadMaterialTextures(this.materialData.id, this.materialData.textures);
    }

    protected loadTexturesEDIT(): IMaterialTextures {
        return this.service.loadMaterialTexturesEDIT();
    }

    protected addToScene() {
        this.unit.view3d.add(this.view3d);
    }

    protected updateView3dVisible() {
        this.view3d.visible = !!this.saveData.isVisible && !this.isUnion;
    }

    protected initPosition(): Vector3 {
        let position: Vector3;

        position = this.getDefaultPositionByType();
        if (this.saveData.initPosition) {
            if (this.saveData.initPosition.x !== undefined) {
                position.x = KitchenHelper.calculateSizeByParent(
                    ''+this.saveData.initPosition.x,
                    this.unit.getWidth(),
                    this.service.getDataForSizeByParent()
                );
            }
            if (this.saveData.initPosition.y !== undefined) {
                position.y = KitchenHelper.calculateSizeByParent(
                    ''+this.saveData.initPosition.y,
                    this.unit.getHeight(),
                    this.service.getDataForSizeByParent()
                );
            }
            if (this.saveData.initPosition.z !== undefined) {
                position.z = KitchenHelper.calculateSizeByParent(
                    ''+this.saveData.initPosition.z,
                    this.unit.getDepth(),
                    this.service.getDataForSizeByParent()
                );
            }
        }
        if (this.saveData.position) {
            if (this.saveData.position.x !== undefined && !isNaN(+this.saveData.position.x)) {
                position.x = +this.saveData.position.x;
            }
            if (this.saveData.position.y !== undefined && !isNaN(+this.saveData.position.y)) {
                position.y = +this.saveData.position.y;
            }
            if (this.saveData.position.z !== undefined && !isNaN(+this.saveData.position.z)) {
                position.z = +this.saveData.position.z;
            }
        }
        if (this.saveData.margin) {
            if (this.saveData.margin.x !== undefined && !isNaN(+this.saveData.margin.x)) {
                position.x += +this.saveData.margin.x;
            }
            if (this.saveData.margin.y !== undefined && !isNaN(+this.saveData.margin.y)) {
                position.y += +this.saveData.margin.y;
            }
            if (this.saveData.margin.z !== undefined && !isNaN(+this.saveData.margin.z)) {
                position.z += +this.saveData.margin.z;
            }
        }

        return position;
    }

    protected getDefaultPositionByType(): Vector3 {
        throw new Error('replace-ThreeKUnitDetail-getDefaultPositionByType');
    }

    protected initRotation(): Euler {
        let rotation: Euler;

        rotation = this.getDefaultRotationByType();
        if (this.saveData.rotation) {
            if (this.saveData.rotation.x !== undefined && !Number.isNaN(+this.saveData.rotation.x)) {
                rotation.x = +this.saveData.rotation.x;
            }
            if (this.saveData.rotation.y !== undefined && !Number.isNaN(+this.saveData.rotation.y)) {
                rotation.y = +this.saveData.rotation.y;
            }
            if (this.saveData.rotation.z !== undefined && !Number.isNaN(+this.saveData.rotation.z)) {
                rotation.z = +this.saveData.rotation.z;
            }
        }

        return rotation;
    }

    protected getDefaultRotationByType(): Euler {
        throw new Error('replace-ThreeKUnitDetail-getDefaultRotationByType');
    }

    protected calculateShapePoints(): Vector2[] {
        let shapePoints: Vector2[] = [];
        let uniquePoints: { [key: string]: boolean } = {};
        let index;

        if (!this.correctLeftPoints || !this.correctRightPoints) {
            throw new Error('error-ThreeKUnitDetail-calculateShapePoints');
        }

        for (index = 0; index <= this.correctLeftPoints.length - 1; index++) {
            if (!uniquePoints[this.correctLeftPoints[index].x + '_' + this.correctLeftPoints[index].y]) {
                shapePoints.push(new Vector2(this.correctLeftPoints[index].x, this.correctLeftPoints[index].y));
                uniquePoints[this.correctLeftPoints[index].x + '_' + this.correctLeftPoints[index].y] = true;
            }

        }
        for (index = this.correctRightPoints.length - 1; index >= 0; index--) {
            if (!uniquePoints[this.correctRightPoints[index].x + '_' + this.correctRightPoints[index].y]) {
                shapePoints.push(new Vector2(this.correctRightPoints[index].x, this.correctRightPoints[index].y));
                uniquePoints[this.correctRightPoints[index].x + '_' + this.correctRightPoints[index].y] = true;
            }
        }
        if (shapePoints.length > 0) {
            shapePoints.push(new Vector2(shapePoints[0].x, shapePoints[0].y));
        }

        return shapePoints;
    }

    protected createShape(shapePoints?: Vector2[]) {
        if (!shapePoints) {
            shapePoints = this.calculateShapePoints();
        }
        this.shapePoints = shapePoints;
        this.shape.setFromPoints(this.shapePoints);
    }

    protected getBodyMaterial() {
        return new MeshStandardMaterial({color: '#818181', envMapIntensity:4})
    }

    protected initSidePoints(type: TSideType, initPoints?: TPoint2D[]): Vector2[] {
        let points: Vector2[] = [];
        let initPoint: TPoint2D;

        if (initPoints && initPoints.length > 0) {
            for (initPoint of initPoints) {
                points.push(new Vector2(initPoint.x, initPoint.y));
            }
        } else {
            points = this.getDefaultPoints(type);
        }

        return points;
    }

    protected getDefaultPoints(type: TSideType): Vector2[] {
        let points;

        points = [
            new Vector2(0, -this.getWidth() / 2),
            new Vector2(0, this.getWidth() / 2)
        ];

        return points;
    }

    protected correctMaterialOffset() {

    }

    protected calculateSidePoints() {
        this.leftPoints = this.initSidePoints(SIDE_TYPE_LEFT, this.saveData.leftPoints);
        this.rightPoints = this.initSidePoints(SIDE_TYPE_RIGHT, this.saveData.rightPoints);
    }

    protected calculateCorrectSidePoints() {
        if (!this.leftPoints || !this.rightPoints) {
            throw new Error('error-ThreeKUnitDetail-calculateCorrectSidePoints');
        }
        this.correctLeftPoints = this.initCorrectPointsBySize(this.leftPoints, -1);
        this.correctRightPoints = this.initCorrectPointsBySize(this.rightPoints, 1);
    }

    protected initCorrectPointsBySize(points: Vector2[], direction: number): Vector2[] {
        let correctPoints: Vector2[];
        let index: number;
        let point: Vector2;
        let correctPoint: Vector2;
        let box: Box2;
        let endWidth: number;

        correctPoints = [];
        box = new Box2();
        box.setFromPoints(points);
        endWidth = box.max.x - box.min.x;
        for (index = 0; index <= points.length - 1; index++) {
            point = points[index];
            correctPoint = point.clone();
            correctPoint.x = direction * ((this.getLength() / 2 - endWidth) + point.x);
            if (Math.abs(correctPoint.x) > this.getLength() / 2) {
                correctPoint.x = direction * this.getLength() / 2;
            }
            if (Math.abs(correctPoint.x) > this.getLength() / 2) {
                correctPoint.x = direction * this.getLength() / 2;
            }
            correctPoints.push(correctPoint);
        }

        return correctPoints;
    }

    protected initThreeUnitSaveData(saveData: ISaveKUnitDetailData): ISaveKUnitDetailData {
        saveData = super.initThreeUnitSaveData(saveData);
        if (saveData.isCalculate === undefined) {
            saveData.isCalculate = true;
        }
        if (saveData.isVisible === undefined) {
            saveData.isVisible = true;
        }
        if (saveData.canUnion === undefined) {
            saveData.canUnion = true;
        }
        saveData.sizes = this.initSizes(saveData);

        return saveData;
    }

    protected initSizes(saveData: ISaveKUnitDetailData): TOptionalSizes {
        let sizes: TOptionalSizes;

        sizes = {
            height: (saveData.sizes !== undefined && saveData.sizes.height !== undefined && !isNaN(+saveData.sizes.height)) ?
                saveData.sizes.height : undefined,
            length: (saveData.sizes !== undefined && saveData.sizes.length !== undefined && !isNaN(+saveData.sizes.length)) ?
                saveData.sizes.length : undefined,
            width: (saveData.sizes !== undefined && saveData.sizes.width !== undefined && !isNaN(+saveData.sizes.width)) ?
            saveData.sizes.width : undefined,
        };
        if (saveData.initSizes) {
            if ((sizes.length === undefined || isNaN(+sizes.length)) && saveData.initSizes.length) {
                sizes.length = KitchenHelper.calculateSizeByParent(
                    saveData.initSizes.length,
                    this.unit.getCorpusSizes().length,
                    this.service.getDataForSizeByParent()
                )
            }
            if ((sizes.height === undefined || isNaN(+sizes.height)) && saveData.initSizes.height) {
                sizes.height = KitchenHelper.calculateSizeByParent(
                    saveData.initSizes.height,
                    this.unit.getCorpusSizes().height,
                    this.service.getDataForSizeByParent()
                )
            }
            if ((sizes.width === undefined || isNaN(+sizes.width)) && saveData.initSizes.width) {
                sizes.width = KitchenHelper.calculateSizeByParent(
                    saveData.initSizes.width,
                    this.unit.getCorpusSizes().width,
                    this.service.getDataForSizeByParent()
                )
            }
        }
        if (!Number.isFinite(sizes.height)) {
            sizes.height = this.initHeight(saveData.positionType);
        }
        if (!Number.isFinite(sizes.length)) {
            sizes.length = this.initLength(saveData.positionType);
        }
        if (!Number.isFinite(sizes.width)) {
            sizes.width = this.initWidth(saveData.positionType);
        }

        return sizes;
    }

    protected initHeight(positionType?: TPositionSideType): number {
        throw new Error('replace-ThreeKUnitDetail-initHeight');
    }

    protected initLength(positionType?: TPositionSideType): number {
        throw new Error('replace-ThreeKUnitDetail-initHeight');
    }

    protected initWidth(positionType?: TPositionSideType): number {
        throw new Error('replace-ThreeKUnitDetail-initHeight');
    }
}