import {Box3, Euler, ExtrudeGeometry, Mesh, MeshStandardMaterial, Vector2, Vector3} from 'three';
import {TSideType} from '../../../../../../common-code/types/TSideType';
import {
    SIDE_TYPE_BACK,
    SIDE_TYPE_FRONT,
    SIDE_TYPE_LEFT,
    SIDE_TYPE_RIGHT, UNIT_PLINTHS_TYPE_BACK_NEIGHBOR, UNIT_PLINTHS_TYPE_DEFAULT
} from '../../../../../../common-code/constants';
import {ThreeKUnitDetail} from '../ThreeKUnitDetail/ThreeKUnitDetail';
import {IThreeKUnitDetail} from '../../../../interfaces/IThreeKUnitDetail';
import {ICoverMainPoints} from '../../../../interfaces/ICoverMainPoints';
import {TPositionSideType} from '../../../../../../common-code/types/TPositionSideType';
import {ThreeUnit} from '../../ThreeUnit/ThreeUnit';
import {IPlinthData} from '../../../../../../common-code/interfaces/materials/IPlinthData';
import {ISaveKUnitDetailData} from '../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';
import {CommonHelper} from 'common-code';
import {IGlobalSidePoints} from '../../../../interfaces/IGlobalSidePoints';
import { IMaterialTextures } from '../../../../interfaces/IMaterialTextures';


export class ThreePlinth extends ThreeKUnitDetail implements IThreeKUnitDetail {
    materialData: IPlinthData;
    materialTextures: IMaterialTextures;

    constructor(options: ISaveKUnitDetailData, unit: ThreeUnit) {
        super(options, unit);
        this.materialData = this.initMaterialData();
        // this.materialTextures = this.loadTexturesEDIT();
        this.materialTextures = this.loadTextures();
      
    }

    public getDefaultRotationByType(): Euler {
        let rotation: Euler;
        switch (this.saveData.positionType) {
            case SIDE_TYPE_LEFT:
                rotation = new Euler(0, 0.5 * Math.PI, 0);
                break;
            case SIDE_TYPE_RIGHT:
                rotation = new Euler(0, -0.5 * Math.PI, 0);
                break;
            case SIDE_TYPE_BACK:
            case SIDE_TYPE_FRONT:
            default:
                rotation = new Euler();
                break;
        }
        return rotation;
    }

    public getGlobalSidePoints(cover: Mesh = this.cover): IGlobalSidePoints {
        return super.getGlobalSidePoints();
    }

    public getDefaultPositionByType(): Vector3 {
        let position: Vector3;
        let coverBox: Box3;
        let corpusCoverBox: Box3;

        coverBox = this.unit.getCoverBox(0);
        corpusCoverBox = this.unit.getCorpusCoverBox(0);
        switch (this.saveData.positionType) {
            case SIDE_TYPE_LEFT:
                position = new Vector3(
                    corpusCoverBox.min.x + this.getHeight() / 2,
                    corpusCoverBox.min.y - this.getWidth() / 2,
                    (this.unit.getPlinthsType() === UNIT_PLINTHS_TYPE_BACK_NEIGHBOR ?
                        coverBox.min.z : corpusCoverBox.min.z) + this.getLength() / 2
                );
                break;
            case SIDE_TYPE_RIGHT:
                position = new Vector3(
                    corpusCoverBox.max.x - this.getHeight() / 2,
                    corpusCoverBox.min.y - this.getWidth() / 2,
                    (this.unit.getPlinthsType() === UNIT_PLINTHS_TYPE_BACK_NEIGHBOR ?
                        coverBox.min.z : corpusCoverBox.min.z) + this.getLength() / 2
                );
                break;
            case SIDE_TYPE_FRONT:
                position = new Vector3(
                    (corpusCoverBox.min.x + corpusCoverBox.max.x) / 2,
                    corpusCoverBox.min.y - this.getWidth() / 2,
                    corpusCoverBox.max.z - this.getHeight() / 2
                );
                break;
            case SIDE_TYPE_BACK:
            default:
                position = new Vector3(
                    (corpusCoverBox.min.x + corpusCoverBox.max.x) / 2,
                    corpusCoverBox.min.y - this.getWidth() / 2,
                    (this.unit.getPlinthsType() === UNIT_PLINTHS_TYPE_BACK_NEIGHBOR ?
                        coverBox.min.z : corpusCoverBox.min.z) + this.getHeight() / 2
                );
                break;
        }

        return position;
    }

    public createBody(): void {
        let geometry;
        let body;
        let extrudeSettings;

        extrudeSettings = {
            steps: 1,
            depth: this.getHeight(),
            bevelEnabled: false
        };
        geometry = new ExtrudeGeometry(this.shape, extrudeSettings);
        geometry.center();
        body = new Mesh(geometry, this.getBodyMaterial());
        body.castShadow = true;
        this.body = body;
        body.name = "body";
        body.matrixAutoUpdate = false;
        body.updateMatrix();
        body.userData.notTransparentForBack = true;
        this.view3d.add(body);
        this.addCoverPoints(this.calculateMeshCoverPoints(body));
    }

    public getBodyMaterial(): MeshStandardMaterial {
        return new MeshStandardMaterial({ 
            roughness: 0.7,
            color: this.materialData.color || '#ffffff',
            emissive: this.materialData.emissiveColor || '#000000',
            emissiveIntensity: 0.35,
            displacementScale: 0,
            map: this.materialTextures.texture || null,
            normalMap: this.materialTextures.normal || null,
            roughnessMap: this.materialTextures.roughness || null,
        })
    }

    public getUnionYPosition(): number {
        return this.getWidth() / 2;
    }

    public initHeight(positionType?: TPositionSideType): number {
        return this.unit.getPlinthDepth();
    }

    public initWidth(positionType?: TPositionSideType): number {
        return this.unit.getPlinthHeight();
    }

    public initLength(positionType?: TPositionSideType): number {
        let length: number;
        let coverBox: Box3;
        let corpusCoverBox: Box3;

        coverBox = this.unit.getCoverBox(0);
        corpusCoverBox = this.unit.getCorpusCoverBox(0);
        switch (positionType) {
            case SIDE_TYPE_LEFT:
            case SIDE_TYPE_RIGHT:
                switch (this.unit.getPlinthsType()) {
                    case UNIT_PLINTHS_TYPE_BACK_NEIGHBOR:
                        length = (coverBox.max.z - coverBox.min.z) - (coverBox.max.z - corpusCoverBox.max.z);
                        break;
                    case UNIT_PLINTHS_TYPE_DEFAULT:
                    default:
                        length = (corpusCoverBox.max.z - corpusCoverBox.min.z);
                        break;
                }
                break;
            case SIDE_TYPE_FRONT:
            case SIDE_TYPE_BACK:
            default:
                length = this.unit.getCorpusSizes().length;
                break;
        }

        return length;
    }

    public getDefaultPoints(type: TSideType): Vector2[] {
        let points;

        points = [
            new Vector2(0, -this.getWidth() / 2),
            new Vector2(0, this.getWidth() / 2)
        ];

        return points;
    }

    public getGlobalMainPoints(cover: Mesh = this.cover): ICoverMainPoints {
        if (!this.correctLeftPoints || !this.correctRightPoints) {
            throw new Error('error-ThreePlinth-getGlobalMainPoints');
        }
        this.view3d.updateMatrixWorld();
        this.globalCoverMainPoints.bottom.pointA.set(
            this.correctLeftPoints[0].x,
            this.correctLeftPoints[0].y,
            0
        ).applyMatrix4(this.view3d.matrixWorld);
        this.globalCoverMainPoints.bottom.pointB.set(
            this.correctRightPoints[0].x,
            this.correctRightPoints[0].y,
            0
        ).applyMatrix4(this.view3d.matrixWorld);
        this.globalCoverMainPoints.top.pointA.set(
            this.correctLeftPoints[this.correctLeftPoints.length - 1].x,
            this.correctLeftPoints[this.correctLeftPoints.length - 1].y,
            0
        ).applyMatrix4(this.view3d.matrixWorld);
        this.globalCoverMainPoints.top.pointB.set(
            this.correctRightPoints[this.correctRightPoints.length - 1].x,
            this.correctRightPoints[this.correctRightPoints.length - 1].y,
            0
        ).applyMatrix4(this.view3d.matrixWorld);

        return this.globalCoverMainPoints;
    }

    public getTechnologMapCuttingGap(): number {
        return this.service.getPlinthCuttingGap(this.getTechnologMapFacadeId());
    }

    protected getOtherDetails(): ThreeKUnitDetail[] {
        return this.unit.plinths || [];
    }

    protected initMaterialData(): IPlinthData {
        return  CommonHelper.deepCopy(this.service.getPlinthMaterial(this.saveData.material, this.getCorpusMaterialId()));
    }

    protected getCorpusMaterialId(): string | undefined {
        return this.unit.getCorpusMaterialId();
    }

    protected initPriceType() {
        if (this.saveData.priceType === undefined && this.service.appConfig.catalog.plinths !== undefined) {
            this.saveData.priceType = this.service.appConfig.catalog.plinths.priceType;
        }
    }
}