import { Group } from 'three'
import { IHandleData } from '../../../../../common-code/interfaces/materials/IHandleData'
import { IHandleModelData } from '../../../../../common-code/interfaces/materials/IHandleModelData'
import { INewHandleData } from './FormHandle'

export const initialErrors = {
    title: '',
}

export const defaultValues = (item: IHandleData | null): INewHandleData => {
    return item
        ? {
              id: item.id,
              title: item.title,
              models: item.models ? item.models : [],
              isDefault: item.isDefault ? item.isDefault : false,
              image: item.image ? item.image : '',
              imageNew: null,   
          }
        : {
              id: '',
              title: '',
              models: [],
              isDefault: false,

              image: '',
              imageNew: null,
          }
}

export interface INewHandleModelData {
    id: string
    width: string
    height: string
    depth: string
    type: string
    ext: string
    error: string
    object3D?: Group
}

export const initialModel = (
    item: IHandleModelData | null
): INewHandleModelData =>
    item
        ? {
              id: item.id,
              width: item.width.toString(),
              height: item.height.toString(),
              depth: item.depth.toString(),
              type: item.type ? item.type : item.id.replace(/_\d+-\d+-\d+/, ''),
              ext: item.ext,
              error: '',
              object3D: item.object3D,
          }
        : {
              id: '',
              width: '0',
              height: '0',
              depth: '0',
              type: 'default',
              ext: 'glb',
              error: '',
              object3D: undefined,
          }
