import React, { useContext, useState } from "react";
import { INewHandleModelData, initialModel } from "../defaultValues";
import { Button } from "../../../../components/Button";
import HadleModel from "./HandleModel";
import { cnFormHandle, INewHandleData } from "../FormHandle";
import ModalHandleModel from "./ModalHandleModel";
import { THandleModelType } from "common-code/lib/types/THandleModelType";
import { TModelExtension } from "../../../../../../common-code/types/TModelExtension";
import { useGltfLoader } from "../../../../../hooks/useLoadModel";

const HandleModels = ({
  newHandle,
  setNewHandle,
}: {
  newHandle: INewHandleData;
  setNewHandle: (newHandle: INewHandleData) => void;
}) => {
  const [newHandleModel, setNewHandleModel] = useState<INewHandleModelData>(
    initialModel(null)
  );
  const [modal, setModal] = React.useState<boolean>(false);
  const { model3D, loadGltfModel, clearGltfModel, loading, error } = useGltfLoader();
  const { models } = newHandle;

  const handleToggleModal = () => {
    setModal(!modal);
  };

  const validateModel = (newHandleModel: INewHandleModelData) => {
    const { width, height, depth, type, ext, error } = newHandleModel;
    if (!width || width === "0") return "Укажите ширину";
    if (!height || height === "0") return "Укажите высоту";
    if (!depth || depth === "0") return "Укажите глубину";
    if (!type) return "Укажите тип";
    return "";
  };

  const createModel = (newHandleModel: INewHandleModelData) => {
    const { width, height, depth, type, ext } = newHandleModel;
    return {
      id: `${type}_${width}-${depth}-${height}`,
      width: Number(width),
      height: Number(height),
      depth: Number(depth),
      type: type as THandleModelType,
      ext: ext as TModelExtension,
      object3D: model3D
    };
  };

  const handleUploadModel = () => {
    let file: File | undefined;
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".gltf,.glb";
    input.onchange = (event) => {
      if (event.target instanceof HTMLInputElement) {
        file = event.target.files?.[0];
      }
      loadGltfModel(file)
        .then((gltfModel) => {
          setNewHandleModel({ ...newHandleModel, object3D: gltfModel });
        })
        .catch((err) => {
           console.error("Ошибка загрузки:", err);
        });
    };
    input.click();
  };

  const handleClearModel = () => {
    clearGltfModel();
  };

  const handleSubmit = () => {
    const error = validateModel(newHandleModel);
    if (error) {
      setNewHandleModel({ ...newHandleModel, error });
      return;
    }
    const modelNew = createModel(newHandleModel);
    setNewHandle({ ...newHandle, models: [...models, modelNew] });
    setNewHandleModel(initialModel(null));
    setModal(false);
  };

  return (
    <>
      <h4 className={cnFormHandle("title")}>Модели ручек</h4>
      {models.map((model, index) => (
        <HadleModel
          model={model}
          model3D={model3D}
          key={`${index}${model.id}`}
          index={index}
          createModel={createModel}
          setNewHandle={setNewHandle}
          handleUploadModel={handleUploadModel}
          handleClearModel={handleClearModel}
          newHandle={newHandle}
          validateModel={validateModel}
        />
      ))}

      <ModalHandleModel
        newHandle={newHandle}
        modal={modal}
        handleToggleModal={handleToggleModal}
        newHandleModel={newHandleModel}
        handleSubmit={handleSubmit}
        handleUploadModel={handleUploadModel}
        handleClearModel={handleClearModel}
        model3D={model3D}
        setNewHandleModel={setNewHandleModel}
        keyValue={`#addShelf`}
      />

      <Button
        className={cnFormHandle("Button")}
        type={"button"}
        text={`Добавить модель ручки`}
        leftIcon={{
          path: "plus2",
          width: 16,
          height: 16,
          marginRight: 8,
        }}
        mods={{
          mods: {
            bkg: "white",
            color: "lightGreen",
            border: "lightGreen",
            fill: "lightGreen",
          },
          hover: {
            mods: {
              bkg: "lightGreen",
              color: "white",
              fill: "white",
            },
          },
        }}
        callBack={handleToggleModal}
      />
    </>
  );
};

export default HandleModels;
