import { useState } from 'react'
import { Group } from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

export const useGltfLoader = () => {
    const [model3D, setModel3D] = useState<Group | null>(null)
    const [error, setError] = useState<Error | null>(null)
    const [loading, setLoading] = useState(false)

    const loadGltfModel = (input?: File | string): Promise<Group> => {
        return new Promise((resolve, reject) => {
            if (!input) {
                const err = new Error('Файл или URL не предоставлен.')
                setError(err)
                reject(err)
                return
            }

            setLoading(true)

            if (typeof input === 'string') {
                // Если входные данные - строка (URL)
                const loader = new GLTFLoader()
                loader.load(
                    input,
                    (gltf) => {
                        setModel3D(gltf.scene)
                        setLoading(false)
                        resolve(gltf.scene)
                    },
                    undefined,
                    (err) => {
                        const loadError = new Error(
                            'Ошибка загрузки модели GLTF по URL: ' + err.message
                        )
                        setError(loadError)
                        setLoading(false)
                        reject(loadError)
                    }
                )
            } else {
                // Если входные данные - файл
                const reader = new FileReader()

                reader.onload = (e) => {
                    const loader = new GLTFLoader()
                    const contents = (e.target as FileReader).result

                    if (contents !== null) {
                        loader.parse(
                            contents,
                            '',
                            (gltf) => {
                                setModel3D(gltf.scene)
                                setLoading(false)
                                resolve(gltf.scene)
                            },
                            (err: ErrorEvent) => {
                                const loadError = new Error(
                                    'Ошибка загрузки модели GLTF: ' +
                                        err.message
                                )
                                setError(loadError)
                                setLoading(false)
                                reject(loadError)
                            }
                        )
                    } else {
                        const err = new Error('Файл пустой или недействителен.')
                        setError(err)
                        setLoading(false)
                        reject(err)
                    }
                }

                reader.onerror = () => {
                    const readError = new Error('Ошибка чтения файла.')
                    setError(readError)
                    setLoading(false)
                    reject(readError)
                }

                reader.readAsArrayBuffer(input)
            }
        })
    }

    const clearGltfModel = () => {
        setModel3D(null)
        setError(null)
        setLoading(false)
    }

    return { model3D, loadGltfModel, clearGltfModel, loading, error }
}
